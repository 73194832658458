// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-machine-item-js": () => import("./../src/templates/machine-item.js" /* webpackChunkName: "component---src-templates-machine-item-js" */),
  "component---src-templates-product-item-js": () => import("./../src/templates/product-item.js" /* webpackChunkName: "component---src-templates-product-item-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-machines-index-js": () => import("./../src/pages/machines/index.js" /* webpackChunkName: "component---src-pages-machines-index-js" */),
  "component---src-pages-products-index-js": () => import("./../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */)
}

